<template>
  <Toast />
  <ul class="clase-breadcrumb">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Reporte de Stock</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>REPORTE GENERAL DE STOCK</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <span><strong>SUCURSAL: </strong></span>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-4">
            <span><strong>NOMBRE PRODUCTO:</strong></span>
            <AutoComplete
              :dropdown="true"
              field="descripcion"
              v-model="productoSelected"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              placeholder="Escriba Nombre Producto"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.descripcion }}</strong
                  ><span><strong> ** </strong></span>
                  <!-- <span v-if="sucursales.length > 2">
                    {{ slotProps.item.stock[0].nombre_sucursal }}
                    <span><strong> ** </strong></span>
                  </span> -->
                  <!-- {{ slotProps.item.fabricas.nombre_proveedor
                  }}<span><strong> **</strong></span> -->
                  {{ slotProps.item.fabrica_nombre }}
                  <span><strong> ** </strong></span>
                  <span
                    :class="
                      stockClass(
                        slotProps.item.stock.length > 0
                          ? sinDecimal(slotProps.item.stock[0].cantidad)
                          : 0
                      )
                    "
                    ><strong>{{
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock[0].cantidad)
                        : 0
                    }}</strong></span
                  >
                </div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>LABORATORIO: </strong></span>
            <AutoComplete
              :dropdown="true"
              field="nombre"
              v-model="laboratorioSelected"
              :suggestions="laboratoriosListadoFiltrado"
              @complete="buscarLaboratorio($event)"
              placeholder="Buscar Laboratorio"
            >
              <template #item="slotProps">
                <div>
                  {{ slotProps.item.nombre }}
                  <!-- **
                  {{ slotProps.item.nombre_proveedor }} -->
                </div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>PROVEEDOR: </strong></span>
            <AutoComplete
              :dropdown="true"
              field="nombre_completo"
              v-model="proveedorSelected"
              :suggestions="proveedoresListadoFiltrado"
              @complete="buscarProveedor($event)"
              placeholder="Buscar Proveedor"
            />
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>STOCK M&Iacute;NIMO: </strong></span>
            <InputNumber v-model="precio_minimo" :showButtons="true" :min="0" />
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>STOCK M&Aacute;XIMO: </strong></span>
            <InputNumber v-model="precio_maximo" :showButtons="true" :min="0" />
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>PRODUCTOS SIN STOCK: </strong></span><br />
            <Checkbox value="sin_stock" v-model="stock_cero" />
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>ACCIONES: </strong></span>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-info"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div
        class="card"
        v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
      >
        <table>
          <thead>
            <tr>
              <td><strong>TOTAL INVERSI&Oacute;N BRUTA: </strong></td>
              <td>
                {{ convertirNumeroGermanicFormat(sumaTotalInversion) }} Bs.
              </td>
            </tr>
            <tr>
              <td><strong>TOTAL VALOR VENTA: </strong></td>
              <td>
                {{ convertirNumeroGermanicFormat(sumaTotalValorVenta) }} Bs.
              </td>
            </tr>
            <tr>
              <td><strong>TOTAL UTILIDAD: </strong></td>
              <td>
                {{ convertirNumeroGermanicFormat(sumaTotalUtilidad) }} Bs.
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="reporte_stock"
        key="id"
        :value="stocks"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        :rows="50"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Stock"
        selectionMode="single click"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Stock..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button
                v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
                label="PDF Stock"
                type="button"
                icon="pi pi-file-pdf"
                class="p-button-outlined p-button-danger"
                v-tooltip.top="'Exportar PDF Stock en Almacen'"
                :loading="generando_pdf"
                :disabled="generando_pdf"
                @click="generarPDF()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
                label="EXCEL Exportar"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success mr-2"
                v-tooltip.top="'Exportar Stock en Almacen'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportStock()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Stock DescargarEXCEL' in auth.user.permissions"
                label="EXCEL Control de Stock"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success"
                v-tooltip.top="'Exportar Control de Stock'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportStockControl()"
              />
            </span>
          </div>
        </template>
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <!--  <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column> -->
        <Column field="cod_producto" header="CÓD" :sortable="true"></Column>
        <Column
          field="sucursal"
          header="SUCURSAL"
          :sortable="true"
          v-if="sucursales.length > 2"
        >
          {{ sucursal }}
        </Column>
        <Column field="laboratorio" header="LABORATORIO" :sortable="true" />
        <Column field="proveedor" header="PROVEEDOR" :sortable="true" />

        <Column field="producto" header="DESCRIPCIÓN/PRODUCTO" />
        <Column
          class="text-right"
          field="cantidad"
          header="STOCK"
          :sortable="true"
        >
          <template #body="{ data }">
            <div :class="claseEstado(data)">
              {{ sinDecimal(data.cantidad) }}
            </div>
          </template>
        </Column>
        <Column
          class="text-right"
          field="precio_compra"
          header="P.COMPRA"
          :sortable="true"
        >
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.precio_compra) }}
            </div>
          </template>
        </Column>
        <Column
          class="text-right"
          field="precio_sugerido"
          header="P.VENTA"
          :sortable="true"
        >
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.precio_sugerido) }}
            </div>
          </template>
        </Column>
        <Column
          v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
          class="text-right"
          field="precio_compra"
          header="T.COMPRA"
        >
          <template #body="{ data }">
            <div>
              {{
                convertirNumeroGermanicFormat(
                  data.precio_compra * data.cantidad
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
          class="text-right"
          field="precio_sugerido"
          header="T.VENTA"
        >
          <template #body="{ data }">
            <div>
              {{
                convertirNumeroGermanicFormat(
                  data.precio_sugerido * data.cantidad
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
          class="text-right"
          field="utilidad"
          header="UTILIDAD"
        >
          <template #body="{ data }">
            <div>
              {{
                convertirNumeroGermanicFormat(
                  data.precio_sugerido * data.cantidad -
                    data.precio_compra * data.cantidad
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          class="text-center"
          field="stock_minimo"
          header="STOCK MÍNIMO"
          :sortable="true"
        >
          <template #body="{ data }">
            <div>
              {{ data.stock_minimo == 0 ? "No Definido" : data.stock_minimo }}
            </div>
          </template>
        </Column>
        <Column field="ubicacion" header="UBICACIÓN" :sortable="true" />
      </DataTable>
    </div>
  </div>
</template>

<script>
import StockService from "@/service/StockService";
import SucursalService from "@/service/SucursalService";
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";
import { sum } from "lodash";

export default {
  data() {
    return {
      stocks: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      enviando: false,
      stock_cero: false,
      exportando: false,
      generando_pdf: false,
      productoSelected: null,
      productosListadoFiltrado: [],
      precio_minimo: 0,
      precio_maximo: 0,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      laboratorioSelected: null,
      laboratoriosListadoFiltrado: [],
      proveedorSelected: null,
      proveedoresListadoFiltrado: [],
      numero_elementos: 0,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.cantidad <= 0) {
          return "outofstock";
        } else if (data.cantidad <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
    };
  },
  stockService: null,
  sucursalService: null,
  productService: null,
  auth: null,
  created() {
    this.stockService = new StockService();
    this.sucursalService = new SucursalService();
    this.initBuscar();
    this.productService = new ProductService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
  },
  computed: {
    sumaTotalInversion() {
      return sum(
        this.stocks.map((item) => {
          return item.cantidad * item.precio_compra;
        })
      );
    },
    sumaTotalValorVenta() {
      return sum(
        this.stocks.map((item) => {
          return item.precio_sugerido * item.cantidad;
        })
      );
    },
    sumaTotalUtilidad() {
      /* sumo el valor de this.sumaTotalValorVenta - this.sumaTotalInversion */
      return this.sumaTotalValorVenta - this.sumaTotalInversion;
    },
  },
  methods: {
    /* rowClass(data) {
      return {
        "p-highlight": data.cantidad <= 0,
      };
    }, */
    incrementar_numero_elementos() {
      this.numero_elementos++;
      return this.numero_elementos;
    },
    buscarLaboratorio(event) {
      setTimeout(() => {
        this.stockService
          .buscarLaboratorioXNombre({ texto: event.query })
          .then((response) => {
            this.laboratoriosListadoFiltrado = [...response.laboratorios];
          });
      }, 250);
    },
    buscarProveedor(event) {
      setTimeout(() => {
        this.stockService
          .buscarProveedorXNombre({ texto: event.query })
          .then((response) => {
            this.proveedoresListadoFiltrado = [...response.proveedores];
          });
      }, 250);
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSelected,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    generarPDF() {
      if (this.stocks.length > 0) {
        this.generando_pdf = true;
        let datos = {
          sucursal: this.sucursalSelected,
          stock_cero: this.stock_cero.length > 0 ? true : false,
          producto: this.productoSelected ? this.productoSelected.id : null,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
        };
        this.stockService
          .imprimirReporte(datos)
          .then(() => {
            this.generando_pdf = false;
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: "Se generó el reporte correctamente",
              life: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.generando_pdf = false;
          });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.stocks = [];
      this.enviando = true;
      this.cargarStocksSucursal();
    },
    cargarStocksSucursal() {
      let datos = {
        sucursal: this.sucursalSelected,
        producto: this.productoSelected,
        precio_minimo: this.precio_minimo,
        precio_maximo: this.precio_maximo,
        stock_cero: this.stock_cero.length > 0 ? true : false,
        proveedor_id: this.proveedorSelected ? this.proveedorSelected.id : null,
        fabrica_id: this.laboratorioSelected
          ? this.laboratorioSelected.id
          : null,
      };
      this.stockService
        .filtrarStockSucursal(datos)
        .then((data) => {
          this.stocks = data.stocks;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((element) => {
            this.sucursales.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportStockControl() {
      if (this.stocks.length > 0) {
        let datos = {
          sucursal: this.sucursalSelected,
          producto: this.productoSelected,
          stock_cero: this.stock_cero,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
        };
        this.stockService.exportStockAllControl(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se exportó el reporte correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    exportStock() {
      if (this.stocks.length > 0) {
        let datos = {
          sucursal: this.sucursalSelected,
          producto: this.productoSelected,
          stock_cero: this.stock_cero,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
        };
        this.stockService.exportStockAll(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se exportó el reporte correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.clase-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
}
</style>
